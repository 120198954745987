import { render, staticRenderFns } from "./DataTablePriorityEditor.vue?vue&type=template&id=dcda7142&scoped=true&"
import script from "./DataTablePriorityEditor.vue?vue&type=script&lang=ts&"
export * from "./DataTablePriorityEditor.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcda7142",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VEditDialog,VIcon,VTextField})
