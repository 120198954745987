import InteractionsClientRowModel from '@/models/client/interactions-client-row';
import { CollapseTimePeriods } from '@/lib/services/Location';
import { GroupedClientRow } from '@/lib/stores/BoardManipulation/CaptainBoardColumnManipulationStore';
import ClientRowModel from '@/models/client/client-row';
import _, { curry } from 'lodash';
import { GroupedRow } from '@/lib/stores/BoardManipulation/models';
import { RowType } from '@/models/client/types/row-type';
import { CycleType, ExtendedPropertyInfo } from '@/models/api';
import { HeadingClientRowModel } from '@/lib/stores/BoardManipulation/ShiftBoardColumnManipulationStore';
import { TimePeriod } from '@/models/client/time-block';
import { SortDirection } from '@/components/Board/GroupingFilteringAndSorting';
import { alphabeticSortingFunc } from '@/lib/stores/BoardManipulation/shared';
import ClientRow from '@/models/client/client-row';

export function extendedPropertyGroupingForClientRowsFunc<T extends ClientRowModel>(extendedPriorityId: string, headings: T[], createAggregateRow: (rows: T[], extendedPropertyInfo: ExtendedPropertyInfo) => T & { occupiedTimes: TimePeriod[], representsRealLocation: boolean }): GroupedRow<T>[] {
    const groupedRows = _.groupBy(headings, h=>h.location?.extendedProperties.filter(ep=>ep.propertyDefinitionId === extendedPriorityId)[0]?.value ?? 'N/A');

    return _.keys(groupedRows).map(k=>{
        const rows = groupedRows[k];
        const occupiedTimes = CollapseTimePeriods(_.flatMap(rows, cr=>cr.tasks.filter(t=>!t.isDeleted && !t.taskType.isDelay)));
        return {
            headingRow: {
                ...createAggregateRow(rows, {
                    _type: 'ExtendedPropertyInfo',
                    id: '_',
                    propertyDefinitionId: extendedPriorityId,
                    value: k,
                }),
                occupiedTimes
            },
            additionalRows: rows
        };
    });
}

export function locationNameComparison<T extends ClientRowModel>(a: T, b: T): number {
    if (a.location!.name! < b.location!.name!) {
        return -1;
    }
    if (a.location!.name! > b.location!.name!) {
        return 1;
    }

    return 0;
}

export function levelSortingFunc<T extends ClientRowModel>(rows: GroupedRow<T>[], direction: SortDirection): GroupedRow<T>[] {
    return alphabeticSortingFunc(rows, direction, (row)=>row.location?.reducedLevelName ?? '');
}

export function alphabeticPropertyAndLocationNameSortingFunc<T extends ClientRowModel>(rows: GroupedRow<T>[], direction: SortDirection, propertySortingFunc: (
    secondaryComparison: (a: T, b: T) => number,
    a: T,
    b: T,
    descending: boolean
) => number): GroupedRow<T>[] {
    const curriedSortLocations = curry(propertySortingFunc)(locationNameComparison);

    const transformingCurriedSortLocations = (a: GroupedRow<T>, b: GroupedRow<T>)=>curriedSortLocations(a.headingRow, b.headingRow, direction === SortDirection.Descending);

    const groupedRows = [
        rows.filter((x) => x.headingRow.rowType === RowType.DURATION).map(x=>({
            headingRow: x.headingRow,
            additionalRows: [...x.additionalRows].sort((a, b) => curriedSortLocations(a, b, direction === SortDirection.Descending))
        })), // development rows
        rows.filter((x) => x.headingRow.rowType === RowType.RATE).map(x=>({
            headingRow: x.headingRow,
            additionalRows: [...x.additionalRows].sort((a, b) => curriedSortLocations(a, b, direction === SortDirection.Descending))
        })), // production rows
        rows.filter((x) => x.headingRow.rowType === RowType.ADHOC).map(x=>({
            headingRow: x.headingRow,
            additionalRows: [...x.additionalRows].sort((a, b) => curriedSortLocations(a, b, direction === SortDirection.Descending))
        })) // services rows
    ];

    return _.flatMap(groupedRows, x=>{
        return [...x].sort(transformingCurriedSortLocations);
    });
}

export function generateExtendedPropertySortingFunc<T extends ClientRowModel>(propId: string): (rows: GroupedRow<T>[], direction: SortDirection)=> GroupedRow<T>[] {
    return _.curryRight(alphabeticSortingFunc)((row: ClientRowModel)=>row.location?.extendedProperties.filter(ep=>ep.propertyDefinitionId === propId)[0]?.value ?? '');
}

export function createAggregateRowForExtendedPropertyFromClientRows<T extends ClientRowModel>(groupedRows: T[], extendedProperty: ExtendedPropertyInfo, transformer: (row: ClientRowModel) => T): T & { occupiedTimes: TimePeriod[], representsRealLocation: boolean }{
    return createAggregateRowFromClientRows<T>(groupedRows, headingRow=>{
        return transformer({
            ...headingRow,
            location: {
                ...headingRow.location!,
                extendedProperties: [
                    extendedProperty
                ]
            }
        });
    });
}

export function createAggregateRowFromClientRows<T extends ClientRowModel>(groupedRows: T[], transformer: (headingRow: ClientRowModel)=>T): T & { occupiedTimes: TimePeriod[], representsRealLocation: boolean } {
    return {
        representsRealLocation: false,
        occupiedTimes: [],
        ...transformer({
            completedCycles: 0,
            eoS: null,
            eosSpan: null,
            eosTask: null,
            id: `agg_${groupedRows[0].id}`,
            location: {
                _type: 'LocationModel',
                id: `agg_${groupedRows[0].id}`,
                name: '',
                unavailable: false,
                cycleType: groupedRows.some(x=>x.rowType === RowType.RATE) ? CycleType.Rate : CycleType.Duration,
                reducedLevelName: _.uniq(groupedRows.map(x=>x.location?.reducedLevelName)).length === 1 ? _.uniq(groupedRows.map(x=>x.location?.reducedLevelName))[0] ?? '' : '',
                reducedLevelId: _.uniq(groupedRows.map(x=>x.location?.reducedLevelId)).length === 1 ? _.uniq(groupedRows.map(x=>x.location?.reducedLevelId))[0] ?? '' : null,
                locationTaskPlanningTag: undefined,
                extendedProperties: [],
                isArchived: false
            },
            nextShiftEoSSpans: [],
            nextShiftSoSSpans: [],
            next24HoursOfLocationDetails: [],
            pendingCycles: 0,
            planningPriorities: [],
            priority: _.min(groupedRows.map(x=>x.priority)) ?? 0,
            rowType: groupedRows.some(x=>x.rowType === RowType.RATE) ? RowType.RATE : RowType.DURATION,
            shiftLocationDetails: {
                _type: 'ShiftLocationDetailViewModel',
                id: '',
                tenant: null,
                isDeleted: false,
                deletedAt: new Date(0,0,1),
                plannedLocationId: null,
                departmentShiftId: null,
                comments: null,
                varianceToPlan: null,
                unavailable: false,
                unavailabilityReason: null,
                stockpileId: null,
                materialDestinationId: null,
                materialTypeId: null,
                images: [],
                commentary: null,
                hazardNotes: []
            },
            soS: null,
            sosSpans: [],
            sosTasks: [],
            stopeInfo: null,
            tags: {
                current: '',
                next: '',
            },
            targetCycles: 0,
            tasks: [],
            weekHasPlannedCycles: false,
            noGoZoneFinder: groupedRows[0].noGoZoneFinder,
        })
    };
}

export function levelGroupingFromClientRowsFunc<T extends ClientRowModel>(headings: T[], createAggregateRow: (rows: T[])=>T): GroupedRow<T>[] {
    const groupedRows = _.groupBy(headings, h=>h.location?.reducedLevelName);

    return _.keys(groupedRows).map(k=>{
        const rows = groupedRows[k];
        const occupiedTimes = CollapseTimePeriods(_.flatMap(rows, cr=>cr.tasks.filter(t=>!t.isDeleted && !t.taskType.isDelay)));
        return {
            headingRow: {
                ...createAggregateRow(rows),
                occupiedTimes,
                representsRealLocation: false
            },
            additionalRows: rows
        };
    });
}