var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.orderedRows,"loading":_vm.loadingRings,"loading-text":("Loading " + (_vm.$termSync('Rings')))},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("title",function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm._f("term")('Rings')))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","small":"","disabled":_vm.locationIsArchived},on:{"click":_vm.onNewClick}},[_vm._v(" New "+_vm._s(_vm._f("term")('Ring'))+" ")])],1)]})]},proxy:true},{key:"item.modifiedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.modifiedAt))+" ")]}},{key:"item.totalDrillLength",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDecimal")(item.totalDrillLength,1))+" ")]}},{key:"item.totalChargeAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDecimal")(item.totalChargeAmount,1))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.locationIsArchived)?_c('v-btn',{staticClass:"float-right",attrs:{"text":"","icon":"","disabled":item.hasAnyWorkStarted},on:{"click":function($event){return _vm.onDeleteClick(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),(!_vm.locationIsArchived)?_c('v-btn',{staticClass:"float-right",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.onEditClick(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }