var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.statuses},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("title",function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm._f("term")('TARP Statuses')))])],1)]})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan.name)+" ")]}},{key:"item.currentStatus",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-icon',{attrs:{"color":_vm.getStatusDisplayColour(item)}},[_vm._v("mdi-shield")]):_vm._e(),_vm._v(_vm._s(_vm.getStatusDisplayText(item))+" ")]}},{key:"item.modifiedAt",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('span',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.status.modifiedAt)))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-btn',{staticClass:"float-right",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.onShowHistory(item)}}},[_c('v-icon',[_vm._v("mdi-information")])],1):_vm._e(),_c('v-btn',{staticClass:"float-right",attrs:{"text":"","icon":"","disabled":_vm.locationIsArchived},on:{"click":function($event){return _vm.onChangeStatus(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }