import { render, staticRenderFns } from "./ShiftBoardRow.vue?vue&type=template&id=78340cce&scoped=true&"
import script from "./ShiftBoardRow.vue?vue&type=script&lang=ts&"
export * from "./ShiftBoardRow.vue?vue&type=script&lang=ts&"
import style0 from "./ShiftBoardRow.vue?vue&type=style&index=0&id=78340cce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78340cce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VBtn,VChip,VIcon,VOverlay,VTooltip})
