import { defineStore } from 'pinia';
import {
    AlterLocationsInStopeCommand,
    SetLocationPlanningPriorityCommand,
    SLCLocationTrackerLocationViewModel,
    UpdateLocationDetailsCommand,
    UpdateLocationStatusCommand,
    UpsertLocationExtendedPropertyCommand,
    StopeLocationTrackerLocationViewModel,
    UpdateLocationTriggerActionResponsePlanStatusCommand,
    DevelopmentLocationTrackerLocationViewModel,
    CommentaryViewModel
} from '@/models/api';
import Locations from '@/lib/data/Locations';
import LocationDetails from '@/lib/data/LocationDetails';
import { useLocationTrackerStore } from '@/lib/stores/LocationTrackerStore';
import {
    ClientCommonLocationTracking,
    ClientDevelopmentLocationTrackerLocationViewModel,
    ClientStopeLocationTrackerLocationViewModel
} from '@/models/client/location-tracker';
import ModelBuilder from '@/lib/ModelBuilder';

function transformLocationViewModelToClientModel(location: StopeLocationTrackerLocationViewModel): ClientStopeLocationTrackerLocationViewModel {
    return {
        locationId: location.locationId,
        commonInformation: {
            ... location.commonInformation!
        },
        productionInformation: {
            ... location.productionInformation!
        },
        stopeInformation: {
            ... location.stopeInformation!
        },
        children: location.children
    };
}

export const useStopeLocationTrackerStore = defineStore('stopeLocationTracker', {
    state: () => {
        return {
            _departmentId: '',
            _mineAreaId: '',
            isInitialized: false,
            locations: [] as ClientStopeLocationTrackerLocationViewModel[],
        };
    },
    actions: {
        async populateStore(mineId: string, departmentId: string, includeArchived: boolean = false): Promise<void> {
            this.isInitialized = false;
            this._departmentId = departmentId;
            this._mineAreaId = mineId;

            await this.populateLocations(departmentId, includeArchived);
            await useLocationTrackerStore().populateStore(mineId, departmentId, this.patchLocationCommonInformation);
            this.isInitialized = true;
        },
        async populateLocations(departmentId: string, includeArchived: boolean = false): Promise<void> {
            const response =
                ((await Locations.GetStopeLocationTrackerDetail(departmentId, includeArchived)) as StopeLocationTrackerLocationViewModel[])
                ?? ([] as ClientStopeLocationTrackerLocationViewModel[]);
            if (response) {
                this.locations.splice(0, this.locations.length, ...response.map(transformLocationViewModelToClientModel));
            }
        },
        async populateLocationRingsForStope(departmentId: string, stopeId: string) {
            await useLocationTrackerStore().populateLocationRingsForStope(departmentId, stopeId);
        },
        clearLocationRingsForOtherStopes(stopeId: string) {
            useLocationTrackerStore().clearLocationRingsForOtherStopes(stopeId);
        },
        clearLocationRingsForAllStopes() {
            useLocationTrackerStore().clearLocationRingsForAllStopes();
        },
        patchLocationCommonInformation(locationId: string, patcher: (location: ClientCommonLocationTracking) => ClientCommonLocationTracking) {
            const locationIndex = this.locations.findIndex(l => l.locationId === locationId);
            if(locationIndex === -1) return;
            const location = this.locations[locationIndex];
            if (location != null && location.commonInformation != null) {
                this.locations.splice(locationIndex, 1, {
                    ...location,
                    commonInformation: patcher(location.commonInformation)
                });
            }
        },
        async executeUpdateLocationStatus(command: UpdateLocationStatusCommand) {
            await Locations.executeUpdateStatusCommand(command);

            const locationToUpdate = this.locations.find(
                (l: ClientStopeLocationTrackerLocationViewModel) => l.locationId === command.locationId
            );

            if (locationToUpdate) locationToUpdate.commonInformation!.locationStatus = command.status;
        },
        async executeUpdateLocationTARPStatus(command: UpdateLocationTriggerActionResponsePlanStatusCommand) {
            await Locations.executeUpdateTARPStatusCommand(command);

            // force refresh
            await this.populateStore(this._mineAreaId, this._departmentId);
        },
        async executeUpdateLocationCommentary(locationId: string, newCommentary: CommentaryViewModel) {
            const command = ModelBuilder.Commands.UpdateLocationCommentaryCommand(locationId, newCommentary.comment ?? '');
            await Locations.executeUpdateCommentary(command);

            await this.populateLocations(this._departmentId);
        },
        async executeUpdateLocationDetails(command: UpdateLocationDetailsCommand) {
            await LocationDetails.send(command);

            const locationToUpdate = this.locations.find(l=>l.locationId === command.locationId);

            if(locationToUpdate != null && locationToUpdate.commonInformation != null) {
                locationToUpdate.commonInformation.details = {
                    _type: 'LocationTrackingLocationDetailsViewModel',
                    stockpileId: command.stockpileId,
                    materialDestinationId: command.materialDestinationId,
                    materialTypeId: command.materialTypeId
                };
            }
        },
        async executeSetPlanningPriority(command: SetLocationPlanningPriorityCommand) {
            await Locations.executeSetPlanningPriorityCommand(command);

            await this.populateLocations(this._departmentId);
        },
        async executeUpdateExtendedProperty(command: UpsertLocationExtendedPropertyCommand): Promise<{ id: string }> {
            const response = await Locations.ExecuteUpsertExtendedProperty(command);

            await this.populateLocations(this._departmentId);
            return response;
        },
        async executeUpdateLocationsInStope(command: AlterLocationsInStopeCommand): Promise<void> {
            const response = await Locations.addSubLocationsToStope(command);

            await this.populateLocations(this._departmentId);
        }
    }
});