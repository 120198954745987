import { GenericDataApi } from '@/lib/data/GenericDataApi';
import { AxiosError } from 'axios';
import AppHttp from '../AppHttp';
import Errors from '../Errors';
import {
    DevelopmentLocationTrackerLocationViewModel, GetLocationCommentaryHistoryQueryResponse,
    HasLocationGotPlannedWorkQueryResponse,
    Location, UpdateLocationCommentaryCommand
} from '@/models/api';
import {
    AlterLocationsInStopeCommand,
    CreateLocationCommand,
    GetLocationTrackingTriggerActionResponsePlanHistoryResponse,
    SetLocationPlanningPriorityCommand,
    SLCLocationTrackerLocationViewModel,
    StopeLocationTrackerLocationViewModel,
    UpdateLocationCommand,
    UpdateLocationStatusCommand,
    UpdateLocationTriggerActionResponsePlanStatusCommand,
    UpsertLocationExtendedPropertyCommand
} from '@/models/api';
import { Dayjs } from 'dayjs';
import RequestHandler from '@/lib/api/RequestHandler';

export class Locations {
    private resource: string = 'Locations';

    public validate(convention: string, value: string) {
        /*
        A = 1 letter         A* = many letters
        N = 1 number         N* = many numbers
        ? = 1 letter or num  ?* = many letters or numbers
        . = any 1 character  .* = many characters

        Other allowed symbols: - _ / and spaces
        e.g. `A._D*` matches H2_123 and HA_444
        */

        const regex = convention
            .replace(/-/g, '[\\-]')
            .replace(/A/g, '[a-z]')
            .replace(/N/g, '[0-9]')
            .replace(/[?]/g, '[a-z0-9]')
            .replace(/\*/g, '+');
        try {
            return { valid: new RegExp(`^${regex}$`, 'i').test(value), badExpression: false };
        } catch (e) {
            return { valid: false, badExpression: true };
        }
    }

    public async export(departmentId: string) {
        const response = await AppHttp.get(`${this.resource}/export/${departmentId}`);
        const data = response ? response.data : null;
        return data;
    }

    public async import(departmentId: string, file: any) {
        const response = await AppHttp.postWithFiles(`${this.resource}/import/${departmentId}`, {}, [file]);
        const data = response ? response.data : null;
        return data;
    }

    public async get(include?: string[]): Promise<Location[]> {
        let query = '';
        if (include) {
            const p: string = include.map((x) => `include=${x}`).join('&');
            query = '?' + p;
        }
        const response = await AppHttp.get(this.resource + query);
        const data = response ? response.data : null;
        return data;
    }

    public async getByDepartment(departmentId: string, include?: string[]): Promise<Location[]> {
        let queryArgs = [`departmentId=${departmentId}`];
        if (include) {
            queryArgs = [...queryArgs, ...include.map((x) => `include=${x}`)];
        }
        const query = '?' + queryArgs.join('&');
        const response = await AppHttp.get(this.resource + query);
        const data = response ? response.data : null;
        return data;
    }

    public async update(command: UpdateLocationCommand) {
        const response = await AppHttp.post(`${this.resource}/Update`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async create(command: CreateLocationCommand) {
        const response = await AppHttp.post(`${this.resource}/Create`, command);
        const data = response ? response.data : null;
        return data;
    }

    public async addSubLocationsToStope(command: AlterLocationsInStopeCommand) {
        await AppHttp.post(`${this.resource}/${command.stopeId}/SubLocations/Add`, command);
    }

    public async delete(id: string) {
        const response = await AppHttp.delete(`${this.resource}/${id}`);
        const data = response ? response.data : null;
        return data;
    }

    public async executeUpdateStatusCommand(command: UpdateLocationStatusCommand) {
        await AppHttp.post(`${this.resource}/commands/UpdateStatus`, command);
    }

    public async executeUpdateTARPStatusCommand(command: UpdateLocationTriggerActionResponsePlanStatusCommand) {
        await AppHttp.post(`${this.resource}/commands/UpdateTARPStatus`, command);
    }

    public async executeSetPlanningPriorityCommand(command: SetLocationPlanningPriorityCommand) {
        await AppHttp.post(`${this.resource}/commands/SetPlanningPriority`, command);
    }

    public async executeUpdateCommentary(command: UpdateLocationCommentaryCommand) {
        const response: any = await RequestHandler.execute(
            'POST',
            this.resource + '/commands/updateCommentary',
            command
        );
        const data = response ? response.data : null;
        return data;
    }

    public async getCommentaryHistory(locationId: string): Promise<GetLocationCommentaryHistoryQueryResponse> {
        const response = await AppHttp.get(`${this.resource}/${locationId}/commentary/history`);
        const data = response ? response.data : null;
        return data;
    }

    public async getTARPStatusChangeHistory(
        locationId: string,
        aspectId: string
    ): Promise<GetLocationTrackingTriggerActionResponsePlanHistoryResponse> {
        const response = await AppHttp.get(`${this.resource}/${locationId}/TARP/${aspectId}/history`);

        const data = response ? response.data : null;
        return data;
    }

    public async archiveLocation(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Archive`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async restoreLocationFromArchive(id: string) {
        const response = await AppHttp.put(`${this.resource}/${id}/Restore`, null);
        const data = response ? response.data : null;
        return data;
    }

    public async hasPlannedWork(locationId: string): Promise<HasLocationGotPlannedWorkQueryResponse> {
        const response = await AppHttp.get(`${this.resource}/${locationId}/HasPlannedWork`);

        const data = response ? response.data : null;
        return data;
    }

    public async GetSLCLocationTrackerDetail(
        departmentId: string,
        includeArchived: boolean = false
    ): Promise<SLCLocationTrackerLocationViewModel[] | null> {
        const response = await AppHttp.get(`${this.resource}/tracking/${departmentId}/SLC?includeArchived=${includeArchived}`);

        const data = response ? response.data : null;
        return data;
    }

    public async GetStopeLocationTrackerDetail(
        departmentId: string,
        includeArchived: boolean = false
    ): Promise<StopeLocationTrackerLocationViewModel[] | null> {
        const response = await AppHttp.get(`${this.resource}/tracking/${departmentId}/Stope?includeArchived=${includeArchived}`);

        const data = response ? response.data : null;
        return data;
    }

    public async GetDevelopmentLocationTrackerDetail(
        departmentId: string,
        includeArchived: boolean = false
    ): Promise<DevelopmentLocationTrackerLocationViewModel[] | null> {
        const response = await AppHttp.get(`${this.resource}/tracking/${departmentId}/Development?includeArchived=${includeArchived}`);

        const data = response ? response.data : null;
        return data;
    }

    public async ExecuteUpsertExtendedProperty(
        command: UpsertLocationExtendedPropertyCommand
    ): Promise<{ id: string }> {
        const response = await AppHttp.post(`${this.resource}/commands/upsertExtendedProperty`, command);
        return response.data;
    }
}

export default new Locations();
