var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[(_vm.data)?_c('v-card',[_c('v-card-title',[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm._f("term")('Equipment Roles')))]),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.create()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Add ")],1),(!!_vm.data && !_vm.data.length)?_c('v-menu',{attrs:{"max-width":"310px","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"small":"","color":"secondary"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v("Import ")],1)]}}],null,false,1904447986),model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}},[_c('v-card',[_c('v-card-text',[_c('input',{ref:"inputUpload",attrs:{"type":"file","accept":"text/csv"},on:{"change":function($event){return _vm.getFile($event)}}})]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.file === null},on:{"click":_vm.importData}},[_vm._v("Upload")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.importDialog = false}}},[_vm._v("cancel")])],1)],1)],1):_vm._e(),(!!_vm.data && _vm.data.length)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","color":"gray"},on:{"click":function($event){return _vm.exportData()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Export ")],1):_vm._e(),(_vm.dirty)?_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.saveList()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v("Save ")],1):_vm._e(),(_vm.dirty)?_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","color":"grey"},on:{"click":function($event){return _vm.loadData()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v("Cancel ")],1):_vm._e(),_c('v-spacer'),_c('admin-sandpit-visibility-selector')],1),_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.sortedAndFilteredNonCrewType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ dirty: item.dirty }},[_c('td',[_c('admin-sandpit-item-name',{attrs:{"is-sandpit":item.isSandpitUseOnly}},[_vm._v(_vm._s(item.name))])],1),_c('td',[_c('div',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(item.displayOrder)+" "),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.up(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.down(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)])])]}}],null,false,1361566489)}),_c('h2',{staticClass:"title"},[_vm._v("Crew Roles")]),_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.sortedAndFilteredCrewType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ dirty: item.dirty }},[_c('td',[_c('admin-sandpit-item-name',{attrs:{"is-sandpit":item.isSandpitUseOnly}},[_vm._v(_vm._s(item.name))])],1),_c('td',[_c('div',{staticClass:"no-wrap"},[_vm._v(" "+_vm._s(item.displayOrder)+" "),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.up(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.down(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1),_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)])])]}}],null,false,1361566489)})],1):_vm._e(),_c('standard-dialog',{attrs:{"render":!!_vm.selected,"value":_vm.showModal,"max-width":900,"persistent":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.selected.id)?_c('span',[_vm._v("Edit")]):_c('span',[_vm._v("Create")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm._f("term")('Equipment Role')))])]},proxy:true},{key:"content",fn:function(){return [_c('v-form',{ref:"form",attrs:{"lazy-validation":true}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"autocomplete":"off","label":"Name","rules":[_vm.$rules.required(), _vm.$rules.maxLength(50)],"counter":"50"},model:{value:(_vm.selected.name),callback:function ($$v) {_vm.$set(_vm.selected, "name", $$v)},expression:"selected.name"}})],1),_c('v-col',[_c('v-checkbox',{attrs:{"label":"Crew Type","hide-details":""},model:{value:(_vm.selected.isCrewType),callback:function ($$v) {_vm.$set(_vm.selected, "isCrewType", $$v)},expression:"selected.isCrewType"}})],1)],1)],1)],1)]},proxy:true},{key:"actions",fn:function(){return [(_vm.selected && _vm.selected.id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v(" Delete ")],1)]}}],null,false,328484605)},[_c('span',[_vm._v("Temporarily disabled")])]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":function($event){_vm.showModal = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])]},proxy:true},{key:"offline-actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":function($event){_vm.showModal = false}}},[_vm._v("Cancel")]),_c('offline-btn',{attrs:{"text":""}})]},proxy:true}])}),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }