import { defineStore } from 'pinia';
import { useSystemStore } from '@/lib/stores/SystemStore';
import ShiftWindowApi from '../data/ShiftWindowApi';
import {
    CrewForWeek,
    CycleType, EquipmentUsageInDepartmentSummaryViewModel,
    RateTaskActualViewModel,
    ShiftCommunication,
    ShiftLocationsViewModel,
    ShiftPlannedTask,
    StaffRole,
    SupervisorReportViewModel,
    TimeUnit, UpdateActualPhysicalsEntriesCommand
} from '@/models/api';
import ClientTaskModel from '@/models/client/client-task';
import { transformRowsToHeadings, transformTaskToClientTask } from './Transforms';
import { useDepartmentStore } from './DepartmentStore';
import { useShiftCommunication, useShiftCrew, useShiftDetails, useShiftEquipment } from './Shift';
import { useHeadingsStore } from './HeadingsStore';
import Equipment from '@/models/client/equipment';
import { transformEquipment } from '@/models/transforms/EquipmentTransforms';
import ClientRowModel from '@/models/client/client-row';
import dayjs from 'dayjs';
import { useMineAreaStore } from './MineAreaStore';
import { Shift } from '@/models/api/Queries/shift';
import { ShiftCrewMember } from '../services/Staff/type';
import ProductionFronts from '@/lib/data/ProductionFronts';
import { useShiftWindowActuals } from '@/lib/stores/Production/ShiftWindowActualsStore';
import ShiftApi from '@/lib/data/Shift';
import Logging from '@/lib/Logging';
import { EventBus, Events } from '@/lib/EventBus';
import { useShiftHighLevelActuals } from '@/lib/stores/Shift/ShiftHighLevelActualsStore';

export interface ShiftDetails {
    shiftId: string;
    weekId: string;
    timeUnits: TimeUnit[];
    hasShiftPlan: boolean;
    hasWeekPlan: boolean;
    hasActuals: boolean;
    deploymentMeetingId: string | null;
    handoverMeetingId: string | null;
    weekDate: string;
    locationsForShift: ShiftLocationsViewModel | null;
    productionActuals: RateTaskActualViewModel[];
    nextShiftDetails: NextShiftDetails[];
    shiftName: string;
}

export interface NextShiftDetails {
    timeUnits: TimeUnit[];
    weekDate: string;
    shiftName: string;
}

interface ClientShift {
    plannedEquipment: Equipment[];
    communication: ShiftCommunication;
    headings: ClientRowModel[];
    shiftDetails: ShiftDetails;
    supervisorReport: SupervisorReportViewModel | null;
    shiftCrewMembers: ShiftCrewMember[];
    shiftRoles: StaffRole[];
    shiftCrew: any;
    assignedCrewIds: string[];
    weekCrewTemplates: CrewForWeek[];
}

interface EmptyShift {
    shiftDetails: { hasWeekPlan: boolean; hasShiftPlan: boolean };
}

export const useShiftWindowStore = defineStore('shiftWindow', {
    state: () => {
        return {
            isInitialized: false,
            _activeShiftIndex: 0,
            shifts: [] as (ClientShift | EmptyShift)[]
        };
    },
    getters: {
        hasPreviousShift(): boolean {
            return this._activeShiftIndex > 0;
        },
        hasNextShift(): boolean {
            return this._activeShiftIndex < this.shifts.length - 1;
        },
        isEmptyShift(): boolean {
            return (
                this.activeShift.shiftDetails.hasShiftPlan === false ||
                this.activeShift.shiftDetails.hasWeekPlan === false
            );
        },
        activeShift(): ClientShift | EmptyShift {
            return this.shifts[this._activeShiftIndex];
        },
        windowShifts(): { name: string; date: string }[] {
            const shifts = this.shifts.map((shift) => {
                // @ts-ignore
                return { name: shift.shiftName!, date: shift.shiftDetails.timeUnits[0].startTime.substring(0, 10) };
            });
            return shifts;
        },
    },
    actions: {
        async GetOtherDepartmentEquipmentUsage(departmentId: string, date: string, shiftNumber: string): Promise<EquipmentUsageInDepartmentSummaryViewModel[]> {
              try {
                  return (await ShiftApi.getEquipmentUsageForShiftWindow(departmentId, date, shiftNumber, 1, 2)).equipmentUsages;
              } catch(e:any){
                  Logging.error(e);
                  // make sure that no matter what we don't fail the whole thing if we can't retrieve the equipment usage
                  EventBus.$emit(Events.ToastError, 'Failed to retrieve equipment usage for other departments.');
                  return [];
              }
        },
        async populateWindow(mineId: string, departmentId: string, date: string, shiftNumber: string) {
            this.isInitialized = false;

            let tasks: ClientTaskModel[] = [];

            if (useSystemStore().isOnline) {
                const current = await ShiftWindowApi.getShiftWindowOffset(mineId, departmentId, date, shiftNumber, 0);

                const hasProductionLocations = current.shift?.headings?.some(x=>x.location?.cycleType === CycleType.Rate) ?? true;

                if(hasProductionLocations)
                    await useShiftWindowActuals().populateWindow(mineId, departmentId, date, shiftNumber, 1, 2);

                useShiftHighLevelActuals().populateWindow(mineId, departmentId, date, shiftNumber, 1, 2);

                const otherDepartmentEquipmentAvailability = await this.GetOtherDepartmentEquipmentUsage(departmentId, date, shiftNumber);

                tasks = current.tasks.map((x: ShiftPlannedTask) =>
                    transformTaskToClientTask(x, useDepartmentStore().taskTypes, useShiftWindowActuals().getBlastPacketTargetsDisplayInformation)
                );

                const transformShiftToClientShift = (x: Shift): ClientShift | EmptyShift => {
                    if (x.hasWeekPlan === false) {
                        return { shiftDetails: { hasWeekPlan: x.hasWeekPlan, hasShiftPlan: x.hasShiftPlan } };
                    }

                    if (x.hasShiftPlan === false) {
                        return { shiftDetails: { hasWeekPlan: x.hasWeekPlan, hasShiftPlan: x.hasShiftPlan } };
                    }

                    const shiftCrew = {
                        _type: 'CrewForShift',
                        ...x.shiftCrew,
                        shiftCrewMembers:
                            x.shiftCrew?.shiftCrewMembers
                                .filter((crewMember) => crewMember.staffRoleId && crewMember.staffId)
                                .map((crewMember) => {
                                    return {
                                        ...crewMember,
                                        staffId: crewMember.staffId!,
                                        staffRoleId: crewMember.staffRoleId!,
                                        staff: useSystemStore().getStaffById(crewMember.staffId!),
                                        staffRole: useSystemStore().getStaffRoleById(crewMember.staffRoleId!),
                                    };
                                }) ?? [],
                    };

                    const shiftRoles =
                        x.shiftCrew?.shiftCrewMembers.reduce((roleList: StaffRole[], crewMember: any) => {
                            const roleIndex = roleList.findIndex((role) => role.id === crewMember.staffRoleId);

                            if (roleIndex === -1) {
                                const role = useSystemStore().getStaffRoleById(crewMember.staffRoleId!);
                                roleList.push(role);
                            }

                            return roleList;
                        }, []) ?? [];

                    return {
                        shiftDetails: {
                            nextShiftDetails: x.nextShifts.map(ns=>(
                                {
                                timeUnits: ns.timeUnits,
                                    // @ts-ignore
                                weekDate: ns.weekOf.substring(0,10),
                                shiftName: ns.shiftName ?? '?',
                                }
                            )),
                            shiftId: x.shiftId!,
                            weekId: x.weekId!,
                            timeUnits: x.timeUnits,
                            hasShiftPlan: x.hasShiftPlan,
                            hasWeekPlan: x.hasWeekPlan,
                            hasActuals: x.hasActuals,
                            handoverMeetingId: x.handoverMeetingId ?? null,
                            deploymentMeetingId: x.deploymentMeetingId ?? null,
                            // @ts-ignore
                            weekDate: x.weekOf.substring(0, 10),
                            locationsForShift: x.locations!,
                            productionActuals: x.productionActuals,
                            shiftName: x.shiftName ?? '?',
                        },
                        plannedEquipment: transformEquipment(
                            x.plannedEquipment,
                            x.shiftId!,
                            useSystemStore().equipmentRoles
                        ),
                        communication: x.communication!,
                        headings: transformRowsToHeadings(
                            x.headings,
                            tasks,
                            dayjs.utc(x.timeUnits[0].startTime),
                            useMineAreaStore().numberOfShiftsPerDay,
                            x.nextShifts.map(ns=>dayjs.utc(ns.timeUnits[0].startTime))
                        ),
                        supervisorReport: x.supervisorReport,
                        // @ts-ignore
                        shiftCrewMembers: shiftCrew.shiftCrewMembers,
                        shiftCrew: shiftCrew,
                        shiftRoles: shiftRoles,
                        assignedCrewIds: x.assignedCrewIds,
                        weekCrewTemplates: x.weekCrewTemplates,
                    };
                };

                this.shifts = [
                    {} as EmptyShift,
                    transformShiftToClientShift(current.shift as Shift),
                    {} as EmptyShift,
                    {} as EmptyShift,
                ];

                const loadShiftWindow = () => {
                    // load the rest of the shifts
                    ShiftWindowApi.getShiftWindowOffset(
                        mineId,
                        departmentId,
                        date,
                        shiftNumber,
                        -1
                    ).then((result)=>{
                        const previousShift = result.shift;

                        return ShiftWindowApi.getShiftWindowOffset(mineId, departmentId, date, shiftNumber, 1)
                            .then(result=>({
                                previousShift: previousShift,
                                nextShift: result.shift
                            }));
                    }).then(({previousShift,nextShift})=>{
                        return ShiftWindowApi.getShiftWindowOffset(mineId, departmentId, date, shiftNumber, 2)
                            .then(result=>({
                                previousShift: previousShift,
                                nextShift: nextShift,
                                shiftAfterNext: result.shift
                            }));
                    }).then(({previousShift,nextShift, shiftAfterNext})=>{
                        this.shifts[0] = transformShiftToClientShift(previousShift as Shift);
                        this.shifts[2] = transformShiftToClientShift(nextShift as Shift);
                        this.shifts[3] = transformShiftToClientShift(shiftAfterNext as Shift);

                        if(!hasProductionLocations){
                            const allShifts = [previousShift, nextShift, shiftAfterNext];

                            if(allShifts.some(s=>s?.headings?.some(x=>x.location?.cycleType === CycleType.Rate)))
                                useShiftWindowActuals().populateWindow(mineId, departmentId, date, shiftNumber, 1, 2);
                        }
                    });
                };

                this._activeShiftIndex = 1;

                if (this.isEmptyShift) {
                    useShiftDetails().setupEmptyShift(
                        this.activeShift.shiftDetails.hasWeekPlan,
                        this.activeShift.shiftDetails.hasShiftPlan
                    );
                    loadShiftWindow();

                    return;
                }

                // GET RID OF
                const productionFrontRulesets = hasProductionLocations ? (await ProductionFronts.listRulesets(departmentId)).productionFronts : [];

                await useShiftDetails().setUpStore(
                    (this.activeShift as ClientShift).shiftDetails,
                    (this.activeShift as ClientShift).supervisorReport
                );

                useHeadingsStore().setUpStore((this.activeShift as ClientShift).headings, productionFrontRulesets, otherDepartmentEquipmentAvailability);

                loadShiftWindow();
            } else {
                if (this.isEmptyShift) {
                    useShiftDetails().setupEmptyShift(
                        this.activeShift.shiftDetails.hasWeekPlan,
                        this.activeShift.shiftDetails.hasShiftPlan
                    );

                    return;
                }

                await useShiftDetails().setUpStore(
                    (this.activeShift as ClientShift).shiftDetails,
                    (this.activeShift as ClientShift).supervisorReport
                );

                useHeadingsStore().updateStore((this.activeShift as ClientShift).headings);
            }

            useShiftCommunication().setUpStore((this.activeShift as ClientShift).communication);
            useShiftEquipment().setUpStore((this.activeShift as ClientShift).plannedEquipment,
                // @ts-ignore
                (this.activeShift as ClientShift).communication.equipmentInWorkshop.map((x) => x.equipmentId),
                //@ts-ignore
                (this.activeShift as ClientShift).communication.equipmentToWorkshop.map((x) => x.equipmentId));

            useShiftCrew().setUpStore(
                (this.activeShift as ClientShift).shiftCrewMembers,
                (this.activeShift as ClientShift).shiftRoles,
                // @ts-ignore
                this.activeShift.assignedCrewIds,
                // @ts-ignore
                this.activeShift.weekCrewTemplates,
                (this.activeShift as ClientShift).shiftCrew?.id
            );

            this.isInitialized = true;
        },
        goToPreviousShift() {
            this._activeShiftIndex -= 1;
        },
        goToNextShift() {
            this._activeShiftIndex += 1;
        },
    },
});
