import {
    ExtendedPropertyInfo, LocationStatus,
    LocationTrackingTriggerActionResponseStatusViewModel,
    MostRecentRingInStateViewModel,
    ShiftLocationDetailViewModel
} from '@/models/api';
import { DataTableHeader } from 'vuetify';
import ClientProductionLocationTrackingInfo from '@/models/client/client-production-location-tracking-info';
import { ClientLocationTrackingLocation } from '@/models/client/location-tracker';

export type LocationTrackerDetail = Pick<ShiftLocationDetailViewModel, 'stockpileId' | 'materialTypeId' | 'materialDestinationId'>

export type ColumnType = "fixed" | "information" | "planning"

export interface LocationTrackerHeader extends DataTableHeader {
    columnType?: ColumnType,
    tooltip?: string | null
}

export interface RecentStateCalculationHelper {
    recentState: MostRecentRingInStateViewModel | null,
    displayName: string
}

export function LocationStatusColour(locationStatus: LocationStatus): string {
    switch (locationStatus) {
        case LocationStatus.Future: return 'green lighten-1';
        case LocationStatus.Current: return 'green darken-3';
        case LocationStatus.OnHold: return 'amber darken-2';
        case LocationStatus.Completed: return 'grey lighten-1';
        default: return 'secondary';
    }
}

export function LocationStatusColourByName(locationStatus: string): string {
    const status: LocationStatus = LocationStatus[(locationStatus.replace(' ', '') as keyof typeof LocationStatus)];
    return LocationStatusColour(status);
}

export function LocationStatusByName(locationStatus: string): LocationStatus {
    return LocationStatus[(locationStatus.replace(' ', '') as keyof typeof LocationStatus)];
}

export function LocationHasNoStatusForTarp (tarpId: string, location: ClientLocationTrackingLocation) {
    return location?.commonInformation?.triggerActionResponsePlanStatuses.findIndex((status: LocationTrackingTriggerActionResponseStatusViewModel)=>status.aspectId===tarpId) === -1;
}
export function LocationHasOneOfSuppliedStatusesForTarp(tarpId: string, tarpStatuses: (string | number)[], location: ClientLocationTrackingLocation){
    return location!.commonInformation!.triggerActionResponsePlanStatuses.findIndex((status: LocationTrackingTriggerActionResponseStatusViewModel)=>status.aspectId===tarpId && tarpStatuses.indexOf(status.statusId ?? '') > -1) > -1;
}

export function LocationHasOneOfSuppliedValuesForExtendedProperty(propId: string, propValues: (string | number)[], location: ClientLocationTrackingLocation){
    return (location?.commonInformation?.extendedProperties.findIndex((prop: ExtendedPropertyInfo)=>prop.propertyDefinitionId===propId && propValues.indexOf(prop.value ?? '') > -1) ?? -1) > -1;
}